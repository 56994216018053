
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class General extends Vue {
  private mediaUrl = process.env.VUE_APP_MEDIA_URL;

  private nombreWeb: any = "";
  private aNoticias: any = [];
  private currentPage = 1;
  private total = 0;

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 9)) {
      this.getInfoNoticia();
    }
  }

  @Watch("$refs")
  private refsWatch(newValue: any) {
    console.log(newValue);
  }

  private mounted() {
    this.getInfoNoticia();
  }

  private getInfoNoticia() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("noticias/general?pagina=" + this.currentPage)
      .then((res: any) => {
        this.aNoticias = res.data.noticias;
        for (let i = 0; i < this.aNoticias.length; i++) {
          if (this.aNoticias[i].fotos.image_intro) {
            this.aNoticias[i].fotos.image_intro =
              process.env.VUE_APP_MEDIA_URL +
              "/" +
              this.aNoticias[i].fotos.image_intro;
          } else {
            this.aNoticias[i].fotos.image_intro =
              process.env.VUE_APP_MEDIA_URL + "/Noticias/default_preview.jpg";
          }
        }
        this.total = res.data.total;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "enero";
    } else if (mesInicio == 2) {
      mesInicio = "febrero";
    } else if (mesInicio == 3) {
      mesInicio = "marzo";
    } else if (mesInicio == 4) {
      mesInicio = "abril";
    } else if (mesInicio == 5) {
      mesInicio = "mayo";
    } else if (mesInicio == 6) {
      mesInicio = "junio";
    } else if (mesInicio == 7) {
      mesInicio = "julio";
    } else if (mesInicio == 8) {
      mesInicio = "agosto";
    } else if (mesInicio == 9) {
      mesInicio = "septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "octubre";
    } else if (mesInicio == 11) {
      mesInicio = "noviembre";
    } else {
      mesInicio = "diciembre";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + " " + anhoInicio;
    }
  }
  private irNoticia(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Publicacion" &&
      this.$router.currentRoute.params.id == idNoticia
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Publicacion",
          params: { id: idNoticia, nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Categoria" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Categoria",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
